<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab title="Certification Information" active>
          <b-card-text className="card_height_course">
            <Certification/>
          </b-card-text>
        </b-tab>
        <b-tab title="Certificate Find">
          <b-card-text className="card_height_course">
            <CertificateFind/>
          </b-card-text>
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {BTabs, BTab, BCardText, BCard} from "bootstrap-vue";
import {required} from "@validations";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
import Certification from './partials/certification.vue';
import CertificateFind from './partials/certificateFind.vue';

export default {
  data() {
    return {};
  },
  mounted() {

  },
  methods: {},
  watch: {},
  components: {
    vSelect,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    Certification,
    CertificateFind,
    Certification,
  },

};
</script>
<style scoped>
.card_height_course {
  height: 70vh;
  overflow-y: scroll;
}

.card_height_course::-webkit-scrollbar {
  width: 6px;
  border-radius: 3px;
  background: #fff;
}

.card_height_course::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #f9f9f9;
}

.card_height_course:hover::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #325288;
}
</style>
  